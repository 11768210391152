<template>
    <div class="heard">
        <div class="top">
            <div class="topLeft">
                <img class="avatar" :src="userInfo.fullImage" alt="" />
                <div class="info">
                    <div class="name">{{userInfo.name}}</div>
                    <div class="position">{{userInfo.departmentName}} | {{userInfo.position}}</div>
                </div>
            </div>
            <div class="topRight">
                <div class="circy" @click="daPhone">
                    <img class="pic" src="../../../assets/fina/phone.png" style="width: 12px;height: 12px;" alt="" />
                </div>
                <div class="circy" @click="showWechat = true">
                    <img class="pic" src="../../../assets/fina/weixin.png" style="width: 17px;height: 14px;" alt="" />
                </div>
            </div>
        </div>
        <!-- 微信二维码弹窗 -->
        <van-popup v-model="showWechat" closeable class="codeBox">
            <img class="codeImg" :src="userInfo.qrCode" alt="" />
            <p class="text">长按扫一扫<br />即可联系我</p>
        </van-popup>
    </div>
</template>
<script>
    import { vcardIndex, newVcardIndex } from "@/api/electronicCard";
    export default {
        data() {
            return {
                employeeId: '',
                userInfo: {},
                showWechat: false,
            };
        },
        created() {
            this.employeeId = this.$route.query.employeeId
            this.getInfo();
        },
        methods: {
            getInfo() {
                newVcardIndex({ employeeId: this.employeeId }).then((res) => {
                    this.userInfo = res.data.employee;
                })
            },
            daPhone() {
                window.location.href = `tel:${this.userInfo.mobile}`;
            },
        }

    };
</script>

<style lang="less" scoped>
    .heard {
        background-image: url('../../../assets/fina/userBg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .top {
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 77px;

        .topLeft {
            display: flex;
            height: 56px;

            .avatar {
                width: 56px;
                height: 56px;
                border-radius: 50%;
            }

            .info {
                margin-left: 9px;

                .name {
                    font-weight: bold;
                    font-size: 18px;
                    color: #4a4a4a;
                    margin-bottom: 8px;
                }

                .position {
                    font-size: 12px;
                    color: #9b9b9b;
                    line-height: 15px;
                }
            }
        }

        .topRight {
            display: flex;

            flex-direction: row;

            .circy {
                width: 32px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(n+2) {
                    margin-left: 20px;
                }
            }
        }


    }

    .codeBox {
        width: 260px;
        height: 286px;
        text-align: center;

        .codeImg {
            margin-top: 30px;
            width: 200px;
            height: 190px;
            text-align: center;
        }

        .text {
            font-size: 16px;
            color: #515151
        }
    }
</style>