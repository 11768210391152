import {request,request_op} from "../plugins/axios";

//获取用户信息
export function getUserInfo (params) {
    return request_op({
        url: '/openUserInfo/customerCode',
        method: 'get',
        params: params
    })
}
export function customerCodeDetail (params) {
    return request_op({
        url: '/openUserInfo/customerCodeDetail',
        method: 'get',
        params: params
    })
}
