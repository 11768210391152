<template>
    <div>
        <div class="index">
            <div class="allUserInfo">
                <div class="userInfo">
                    <div class="content">
                        <div class="contentLeft">
                            <img class="avatar" :src="employeeData.fullImage" alt="" />
                            <div class="contentBot">
                                <div class="round" @click="daPhone">
                                    <img class="phone" :src="phoneiconImg" alt="" />
                                </div>
                                <div class="round" @click="showWechat = true">
                                    <img class="phone" :src="weChatImg" alt="" style="width: 16px; height: 13px" />
                                </div>
                            </div>
                        </div>
                        <div class="contentRight">
                            <img class="logo" :src="logoUrl" alt="" />
                            <div>
                                <div class="name">
                                    <div class="name2">
                                        <span class="span" v-if="employeeData.name.split('').length < 6">{{
                                            employeeData.name }}</span>
                                        <span class="span2 marquee-wrap" v-else>{{
                                            employeeData.name
                                            }}</span>
                                    </div>
                                </div>
                                <div class="position">
                                    {{ employeeData.departmentName }}&nbsp;
                                    {{ employeeData.position }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail" :style="{ height: employeeData == '' ? '50px' : '' }">
                    <div class="expand" v-if="showDetail == false" @click="showDetail = !showDetail">
                        <span>展开全部信息</span> &emsp;<img src="../../assets/index/xia.png" alt="" />
                    </div>
                    <div class="expand" v-else @click="showDetail = !showDetail">
                        <span>收起</span> &emsp;<img src="../../assets/index/shang.png" alt="" />
                    </div>

                    <div v-if="showDetail == true" class="detailbg">
                        <div class="intrudction">
                            个人简介：{{ employeeData.introduction }}
                        </div>
                        <div class="intrudction">
                            资质证书：<span v-for="(cs, j) in employeeData.certificates" :key="j">{{ cs }}&emsp;</span>
                        </div>
                        <div class="intrudction">
                            服务格言：{{ employeeData.serviceMotto }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="score">
            <div class="score_heard">
                <span class="t1">服务评价</span>
                <span class="t2">满意请给5星呀</span>
            </div>
            <div class="centerBox" style="margin: 22px 0px 17px 0px;">
                <van-rate v-model="scoreParams.serviceEvaluation" :size="29" color="#ffd21e" void-icon="star"
                    void-color="#eee" />
            </div>
            <div class="centerBox p10">
                <span class="title">专业性</span>
                <van-rate v-model="scoreParams.speciality" :size="24" color="#ffd21e" void-icon="star"
                    void-color="#eee" />
            </div>
            <div class="centerBox p10">
                <span class="title">服务态度</span>
                <van-rate v-model="scoreParams.serviceAttitude" :size="24" color="#ffd21e" void-icon="star"
                    void-color="#eee" />
            </div>
            <div class="centerBox p10">
                <span class="title">时效性</span>
                <van-rate v-model="scoreParams.timeliness" :size="24" color="#ffd21e" void-icon="star"
                    void-color="#eee" />
            </div>
            <van-field class="inputBox" v-model="scoreParams.evaluate" rows="7" autosize type="textarea"
                placeholder="说点什么吧" show-word-limit />
            <div class="btn" @click="submit">确定评价</div>

        </div>
        <!-- 微信二维码弹窗 -->
        <van-popup v-model="showWechat" closeable style="width: 260px; height: 286px; text-align: center">
            <img class="codeImg" :src="qrCode" alt="" />
            <p style="font-size: 14px; color: #515151">长按扫一扫<br />即可联系我</p>
        </van-popup>
    </div>
</template>
<script>
    import { openUserInfoApi, newVcardIndex, contactEvaluate } from "@/api/electronicCard";
    import { Rate } from 'vant';
    export default {
        data() {
            return {
                value: 4,
                checkStyle: "",
                bgList: [
                    require("../../assets/index/bg.png"),
                    require("../../assets/index/yellowBg.png"),
                    require("../../assets/index/purpleBg.png"),
                    require("../../assets/index/skyblueBg.png"),
                    require("../../assets/index/orangeBg.png"),
                    require("../../assets/index/cyanBg.png"),
                    require("../../assets/index/redBg.png"),
                ],
                topBgList: [
                    require("../../assets/index/infoBg.png"),
                    require("../../assets/index/yellow.png"),
                    require("../../assets/index/purpleTopBg.png"),
                    require("../../assets/index/skyblueTopBg.png"),
                    require("../../assets/index/orangeTopBg.png"),
                    require("../../assets/index/cyanTopBg.png"),
                    require("../../assets/index/redTopBg.png"),
                ],
                phoneiconList: [
                    require("../../assets/index/phone.png"),
                    require("../../assets/index/yellowPhone.png"),
                    require("../../assets/index/purplePhone.png"),
                    require("../../assets/index/skybluePhone.png"),
                    require("../../assets/index/orangePhone.png"),
                    require("../../assets/index/cyanPhone.png"),
                    require("../../assets/index/redPhone.png"),

                ],
                wechaticonList: [
                    require("../../assets/index/wechat.png"),
                    require("../../assets/index/yellowWechat.png"),
                    require("../../assets/index/purpleWechat.png"),
                    require("../../assets/index/skyblueWechat.png"),
                    require("../../assets/index/orangeWechat.png"),
                    require("../../assets/index/cyanWechat.png"),
                    require("../../assets/index/redWechat.png"),
                ],
                wositeList: [require('../../static/wowebsite/wowlogo.png'), require('../../assets/index/yellowWosite.png'), require('../../assets/index/purpleWosite.png'), require('../../assets/index/skyblueWosite.png'), require('../../assets/index/orangeWosite.png'), require('../../assets/index/cyanWosite.png'), require('../../assets/index/redWosite.png')],
                classifyBgList: [
                    "#58b2e7",
                    "#E1BB7C",
                    "#6D76FE",
                    "#6A98C9",
                    "#E84C43",
                    "#CCC895",
                    "#FF3920",
                ],
                phoneiconImg: "",
                weChatImg: "",
                wositeImg: '',
                showDetail: false,
                showWechat: false,
                logoUrl: "",
                departmentName: "",
                qrCode: "",
                phoneNum: "",
                employeeId: "",
                classifyBg: "",
                unionId: '',
                employeeData: {},
                scoreParams: {
                    serviceEvaluation: 0,
                    speciality: 0,
                    serviceAttitude: 0,
                    timeliness: 0,
                    evaluate: ''
                }
            }
        },
        created() {
            this.employeeId = this.$route.query.employeeId;
            this.getOpenUserInfo();//授权登录
        },
        methods: {
            getOpenUserInfo() {
                let that = this;
                openUserInfoApi({
                    employeeId: this.employeeId,
                }).then((res) => {
                    if (res.data.openid === undefined) {
                        let redirectUrl =
                            location.protocol +
                            "//" +
                            location.host +
                            "/auth/vcard?employeeId=" +
                            that.employeeId +
                            "&target=" +
                            encodeURIComponent("/contact/evaluate" + location.search)
                        window.location.href = redirectUrl;
                        return;
                    }
                    this.getInfo()//获取用户信息
                });
            },
            getInfo() {
                newVcardIndex({ employeeId: this.employeeId }).then((res) => {
                    this.employeeData = res.data.employee;
                    const data = res.data;
                    this.logoUrl = data.logo
                        ? data.logo
                        : process.env.VUE_APP_API_BASE_URL + "/static/yunnan.png";
                    this.phoneNum = data.employee.mobile;
                    var that = this;
                    this.departmentName = data.employee ? data.employee.departmentName : "";
                    this.qrCode = data.employee ? data.employee.qrCode : "";
                    this.checkStyle = data.style;
                    //样式更换
                    setTimeout(() => {
                        this.phoneiconImg = this.phoneiconList[this.checkStyle - 1];
                        this.weChatImg = this.wechaticonList[this.checkStyle - 1];
                        this.wositeImg = this.wositeList[this.checkStyle - 1]
                        const bodyElement = document.querySelector(".index");
                        bodyElement.style.backgroundImage = `url(${this.bgList[this.checkStyle - 1]
                            })`;
                        const topImg = document.querySelector(".userInfo");
                        topImg.style.backgroundImage = `url(${this.topBgList[this.checkStyle - 1]
                            })`;
                        const bgColor = document.querySelectorAll(".numbg");
                        for (let i = 0; i < bgColor.length; i++) {
                            bgColor[i].style.background =
                                this.classifyBgList[this.checkStyle - 1];
                        }
                        this.classifyBg = document.querySelector(".active");
                        this.classifyBg.style.background =
                            this.classifyBgList[this.checkStyle - 1];
                    }, 800);
                })
            },
            daPhone() {
                window.location.href = `tel:${this.phoneNum}`;
            },
            submit() {
                let params = {
                    ...this.scoreParams,
                    employeeId: this.employeeId
                }
                contactEvaluate(params).then((res) => {
                    if (res.code == 200) {
                        this.$toast("提交成功!");
                    }

                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .index {
        width: 100%;
        padding: 12px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #ffffff;
        background: url("../../assets/index/bg.png");
        background-size: 100% 812px;
    }

    .allUserInfo {
        width: 100%;

        .userInfo {
            width: 100%;
            background: url("../../assets/index/infoBg.png") no-repeat;
            background-size: 100% 100%;
            height: 207px;

            .content {
                width: 100%;
                height: 207px;
                display: flex;

                .contentLeft {
                    margin: 36px 90px 35px 36px;
                    width: 78px;
                    height: 134px;

                    .avatar {
                        width: 78px;
                        height: 78px;
                        border-radius: 50%;
                    }

                    .contentBot {
                        width: 78px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 24px;
                    }

                    .round {
                        width: 32px;
                        height: 32px;
                        background: #ffffff;
                        text-align: center;
                        border-radius: 50%;
                        line-height: 32px;
                    }

                    .phone {
                        width: 12px;
                        height: 12px;
                    }
                }

                .contentRight {
                    .logo {
                        margin: 22px 0 40px 0;
                        width: 138px;
                        height: 28px;
                    }

                    .name {
                        .name2 {
                            height: 24px;
                            line-height: 22px;
                            display: inline-block;
                            overflow: hidden;
                        }

                        .span {
                            display: inline-block;
                            padding: 0 8px 8px 9px;
                            font-size: 18px;
                            font-weight: bold;
                            color: #000000;
                        }

                        .span2 {
                            width: 134px;
                            animation: marquee-content 10s infinite linear;
                            display: inline-block;
                            padding: 0 8px 10px 9px;
                            font-size: 18px;
                            font-weight: bold;
                            color: #000000;
                            white-space: nowrap;
                        }

                        @keyframes marquee-content {

                            0%,
                            30% {
                                transform: translateX(0);
                            }

                            70%,
                            100% {
                                transform: translateX(-160%);
                            }
                        }

                        .nameImg {
                            margin-bottom: 6px;
                            margin-left: 4px;
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .position {
                        padding-left: 9px;
                        font-size: 12px;
                        color: #4a4a4a;
                    }
                }
            }
        }

        .detail {
            .intrudction {
                margin-left: 12px;
                margin-bottom: 10px;
                width: 94%;
                min-height: 17px;
                font-size: 12px;
                color: #9b9b9b;
                line-height: 17px;
                margin-top: 10px;
            }
        }

        .expand {
            width: 100px;
            margin: 0 auto;
            text-align: center;
            font-size: 12px;
            color: #4a4a4a;
            margin-top: 14px;
            margin-bottom: 14px;

            img {
                width: 9px;
                height: 5px;
            }
        }

        .detailbg {
            // background: url("../../assets/index/expandBg.png") no-repeat;
            padding: 2px 0 12px 0;
            background-size: 100% 100%;
            background: rgb(253, 253, 253, 0.6);
        }
    }

    .score {
        width: 100%;
        padding: 12px;

        .score_heard {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .t1 {
                font-weight: bold;
                font-size: 17px;
                color: #000000;
            }

            .t2 {
                font-weight: 400;
                font-size: 12px;
                color: #999999;
            }
        }

        .centerBox {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .title {
                font-weight: 400;
                font-size: 15px;
                color: #000000;
            }
        }

        .p10 {
            padding: 13px 50px;
            display: flex;
            justify-content: space-between;
        }

        .inputBox {
            width: 100%;
            height: 189px;
            background: #F6F6F6;
            border-radius: 4px;
            margin-top: 13px;
        }

        .btn {
            margin: 28px 0px;
            width: 100%;
            line-height: 48px;
            text-align: center;
            height: 48px;
            background: #3974C6;
            border-radius: 5px;
            font-size: 17px;
            color: #FFFFFF;
        }
    }

    .codeImg {
        margin-top: 30px;
        width: 200px;
        height: 190px;
        text-align: center;
    }
</style>