<template>
    <div class="page">
        <div v-html="content"></div>
    </div>
</template>
<script>
    export default {
        components: {

        },
        data() {
            return {
                content: "",
                b:''
            }
        },
        created() {
            this.content = this.$route.query.text;
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
<style lang="less" scoped>
    ::v-deep img {
        max-width: 100%;
    }
    ::v-deep video {
        max-width: 100%;
    }
    .page {
        padding: 15px;
    }
</style>