import {request, request_op} from "../plugins/axios";

export function getBanner (params) {
    return request({
        url: '/financial/banner',
        method: 'get',
        params: params
    })
}
//理财首页
export function getIndex (params) {
    return request({
        url: '/financial/index',
        method: 'get',
        params: params
    })
}
//获取分类
export function getWkCategory (params) {
    return request({
        url: '/financial/getOpCategory',
        method: 'get',
        params: params
    })
}
//置顶数据
export function getTop (params) {
    return request({
        url: '/financial/top',
        method: 'get',
        params: params
    })
}
//详情
export function getDetail (params) {
    return request({
        url: '/financial/detail',
        method: 'get',
        params: params
    })
}


