<template>
    <div class="">
        <userInfo />
        <div class="content">
            <!-- banner图 -->
            <div class="banner" v-if='bankBannerImg.length!=0'>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <div v-for="(item,index) in bankBannerImg" :key="index">
                        <van-swipe-item>
                            <img style="width: 100%;height: 150px;border-radius:10px" :src="item.img"
                                @click="bannerBtn(item)" alt="">
                        </van-swipe-item>
                    </div>
                </van-swipe>
            </div>
            <!-- 功能选项  -->
            <div class="tab" v-if='categoryData.length!=0'
                :style="categoryData.length<=4?'flex-wrap: wrap;':categoryData.length>8?'flex-flow: column wrap;height:155px':'flex-wrap: wrap;'">
                <div class="tab_box" v-for="(item,index) in firstFiveCategories" :key="index">
                    <div class="box" v-if="index<=3" @click="goPage(item)"> <img :src="item.avatar" alt="">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="box" v-if="index==4" @click="goPage(item)"> <img :src="item.avatar" alt="">
                        <span>更多理财</span>
                    </div>
                </div>
            </div>
            <!-- 精选理财 -->
            <div class="selectBox">
                <div class="heard">
                    <span class="title">精选理财</span>
                    <span class="tips">专家精挑严选</span>
                </div>
                <div class="m4">
                    <div class="prolict" v-for="(item,index) in topData" :key="index">
                        <div class="left-herd">
                            <div class="herad-name">{{item.name}}</div>
                            <div class="herad-tips">{{item.keywords[0]}}</div>
                        </div>
                        <div class="annua">{{item.annualizedRate[0]}}%</div>
                        <div class="annualized">{{item.annualizedNotes}}</div>
                    </div>

                </div>
            </div>
            <!-- 分类内容 -->
            <div class="cateBox" v-for="(item,index) in topFourData" :key="index">
                <div class="head">
                    <div class="title">{{item.name}}</div>
                    <div class="more" @click="goPage()">更多</div>
                </div>
                <div class="m5">
                    <div v-if="showlist(item.id)==0">
                        <van-empty description="暂无内容" />
                    </div>
                    <div>
                        <div class="listBox" v-for="(i,l) in showlist(item.id)" :key="l" @click="goDetail(i)">
                            <div class="top">
                                <div class="box-left">
                                    <div class="n1">{{i.annualizedRate}}%</div>
                                    <div class="n2">{{i.annualizedNotes}}</div>
                                </div>
                                <div class="box-right">
                                    <div class="n1">{{i.characteristic}}</div>
                                    <div class="n2">{{i.salesType==1?'本行':'代销'}}｜{{i.salesNotes}}</div>
                                </div>
                            </div>
                            <div class="bom">
                                <showMore :length="50" :longStr="i.briefIntroduction" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import userInfo from "./components/userInfo.vue";
    import showMore from "./components/showMore.vue";
    import { getBanner, getIndex, getWkCategory, getTop } from '@/api/financial'
    export default {
        components: { userInfo, showMore },
        data() {
            return {
                employeeId: '',
                bankBannerImg: [],
                queryJson: {
                    page: 1,
                    perPage: 5
                },
                categoryData: [],
                currentGroup: {},
                topFourData: [],//前4分类
                topFourList: [],//前4分类列表数据
                topData: [],//置顶数据
            }
        },
        computed: {
            firstFiveCategories() {
                return this.categoryData.slice(0, 5);
            }
        },
        created() {
            this.employeeId = this.$route.query.employeeId
            this.getBannerData()
            this.getCategory()
            this.getTopData()
        },
        mounted() {

        },
        methods: {
            //置顶数据
            getTopData() {
                getTop({ employeeId: this.employeeId }).then((res) => {
                    let newData = res.data.map((i) => {
                        i.keywords = i.keywords.split(', ')
                        return i
                    })
                    this.topData = newData
                })
            },
            //banner数据
            getBannerData() {
                getBanner({ employeeId: this.employeeId }).then((res) => {
                    this.bankBannerImg = res.data
                })
            },
            //列表数据
            getData(id) {
                let params = {
                    ...this.queryJson,
                    employeeId: this.employeeId,
                    groupid: id
                }
                getIndex(params).then((res) => {
                    let json = {
                        id: id,
                        data: res.data.list
                    }
                    this.topFourList.push(json)
                })
            },
            //获取分类
            getCategory() {
                getWkCategory({ employeeId: this.employeeId }).then((res) => {
                    this.categoryData = res.data
                    this.topFourData = res.data.slice(0, 4)//取前4分类
                    this.currentGroup = res.data.length == 0 ? '' : res.data[0]
                    this.resultData()
                })
            },
            //获取前4个分类数据
            resultData() {
                this.topFourList = []
                this.topFourData.forEach((item) => {
                    this.getData(item.id)
                })
            },
            showlist(id) {
                let resultData = this.topFourList.filter(res => res.id == id)
                return resultData.length != 0 ? resultData[0].data : []
            },
            showlength(id) {
                let resultData = this.topFourList.filter(res => res.id == id)
                return resultData.length != 0 ? resultData[0].data.length : 0
            },
            //前往列表详情
            goPage() {
                this.$router.push({
                    path: "/financial/list",
                    query: {
                        employeeId: this.employeeId
                    },
                });
            },
            //前往详情页
            goDetail(row) {
                this.$router.push({
                    path: "/financial/detail",
                    query: {
                        id: row.id,
                        employeeId: this.$route.query.employeeId
                    },
                });
            },
            //banner点击事件
            bannerBtn(row) {
                if (row.detailType == 2) {
                    this.$router.push({
                        path: "/wowebsite/richtext",
                        query: { text: row.detail },
                    });
                } else if (row.detailType == 3) {
                    location.href = row.detail;
                }
            },

        }
    }
</script>

<style lang="less" scoped>
    .banner {
        margin-top: 5px;
        border-radius: 18px;
        width: 100%;
        height: 150px;
    }

    .content {
        padding: 15px 20px;
    }

    .tab {
        display: flex;
        justify-content: flex-start;
        padding-top: 25px;
        overflow-x: scroll;

        .tab_box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 20%;
            height: 70px;

            .box {
                display: flex;
                justify-content: center;
                flex-direction: column;

                img {
                    position: relative;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    width: 37px;
                    height: 37px;
                }

                span {
                    text-align: center;
                    margin-top: 12px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #4A4A4A;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .selectBox {
        margin-top: 25px;

        .heard {
            .title {
                font-weight: 600;
                font-size: 17px;
                color: #000000;
            }

            .tips {
                margin-left: 8px;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
            }
        }

        .m4 {
            margin-top: 15px;
            width: 100%;
            padding: 10px 18px;
            background: linear-gradient(180deg, #FFF7ED 0%, #FFFFFF 100%);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .prolict {
                width: 50%;
                margin-top: 15px;

                .left-herd {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .herad-name {
                        font-weight: 600;
                        font-size: 17px;
                        color: #000000;
                        max-width: 95px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .herad-tips {
                        width: 57px;
                        margin-left: 10px;
                        background: #FFF8E9;
                        border-radius: 2px;
                        font-weight: 400;
                        font-size: 12px;
                        color: #93630D;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .annua {
                    font-weight: 600;
                    font-size: 24px;
                    color: #D81E06;
                    margin: 7px 0px 5px 0px;
                }

                .Annualized {
                    font-weight: 400;
                    font-size: 14px;
                    color: #999999;
                }

            }
        }
    }

    .cateBox {
        margin-top: 25px;

        .head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 17px;
                color: #000000;
            }

            .more {
                font-weight: 400;
                font-size: 12px;
                color: #999999;
            }
        }

        .m5 {
            margin-top: 15px;
            width: 100%;
            padding: 10px 7px;
            background: linear-gradient(180deg, #FFF7ED 0%, #FFFFFF 100%);
            border-radius: 4px;

            .listBox {
                margin-top: 20px;

                .top {
                    display: flex;
                    flex-direction: row;

                    .box-left {
                        margin-left: 12px;

                        .n1 {
                            width: 75px;
                            height: 29px;
                            line-height: 29px;
                            font-weight: 600;
                            font-size: 21px;
                            color: #D81E06;
                        }

                        .n2 {
                            width: 75px;
                            margin-top: 8px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #515151;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .box-right {
                        width: calc(100% - 125px);
                        margin-left: 40px;
                        display: flex;
                        flex-direction: column;

                        .n1 {
                            height: 29px;
                            line-height: 29px;
                            font-weight: 600;
                            font-size: 17px;
                            color: #000000;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .n2 {
                            margin-top: 8px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #515151;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                .bom {
                    margin-top: 15px;
                    width: 100%;
                    padding: 7px;
                    min-height: 51px;
                    background: #FFF8E9;
                    border-radius: 2px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #515151;
                }
            }
        }
    }
</style>