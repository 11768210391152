<template>
    <div class="page">
        <!-- <img v-if="posterImg!=''" :src="posterImg" class="boxQc" alt=""> -->
        <div class="cardBox"  v-if="posterImg!=''">
            <div class="styleBox">
                    <div class="boxHeard">
                        <div class="title">
                            <span>{{showName}}</span>
                        </div>
                    </div>
                    <div class="boxBom">
                        <img  :src="posterImg" alt="" />
                        <div class="tips">{{styletips}}</div>
                    </div>
            </div>
        </div>
        <span v-if="msg!=''" style="width: 100%;text-align: center;">{{msg}}</span>
    </div>
</template>
<script>
    import { getUserInfo,customerCodeDetail } from '@/api/customerCode'
    export default {
        data() {
            return {
                id:null,
                userInfo:{},
                posterImg:'',
                showName:'',
                msg:'',
                styletips:''
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.getInfo()//获取用户信息
        },
        mounted() {
        },
        methods: {
            //获取分享内容
            getData() {
                customerCodeDetail({ id: this.id }).then((res) => {
                    //状态
                    if (res.code == 200) {
                       if(res.data.status==0){
                        //0：不是客户，使用返回的url显示活码二维码
                        this.posterImg=res.data.url
                        this.showName=res.data.name
                        this.styletips='扫码加客服人员哦~'
                       }
                       if(res.data.status==1){
                        //1:未达标客户，使用返回的url直接跳转
                        window.location.href = res.data.url;
                       }
                       if(res.data.status==2){
                        //2：达标客户，使用返回的url跳转
                        window.location.href = res.data.url;
                       }
                       if(res.data.status==3){
                        //3：达标显示群聊，显示下面的url
                         this.posterImg=res.data.url
                         this.showName=res.data.name
                         this.styletips='扫码加群哦~'
                       }
                       if(res.data.status==-1){
                          //-1：群聊人数已满
                          this.msg='群聊人数已满';
                          this.$message.info('群聊人数已满');
                       }
                    }
                })
            },
            //获取用户信息
            getInfo() {
                let that = this;
                //如果为空数据  则重定向页面
                getUserInfo({ id: this.id }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.openid === undefined) {
                            let redirectUrl = location.protocol + "//" + location.host + "/auth/customerCode?id=" + that.id + "&target=" + encodeURIComponent("/customerCode/detail" + location.search);
                            window.location.href = redirectUrl;
                            return
                        }
                        this.userInfo = res.data
                        this.getData()
                    }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        padding: 15px 12px 19px 12px;
        height: 100%;

        .boxQc{
            margin: 0 auto;
            margin-top: 25px;
            background: #FFFFFF;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 150px;
        }
        .cardBox {
        padding: 32px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: #F2F3F7; */

        .styleBox {
            width: 328px;
            height: 483px;

            .boxHeard {
                width: 100%;
                height: 183px;
                border-radius: 12px 12px 0px 0px;
                padding: 30px 23px;
                background-image: url('../../assets/index/1@3x.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        font-size: 17px;
                        font-weight: 400;
                        color: #999999;
                    }

                    img {
                        width: 41px;
                        height: 41px;
                    }
                }

                .tips {
                    margin-top: 15px;
                    font-size: 23px;
                    font-weight: 500;
                    color: #999999;
                }
            }

            .boxBom {
                padding: 60px;
                background: #FFFFFF;
                border-radius: 0px 0px 12px 12px;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;

                img {
                    width: 196px;
                    height: 196px;
                }
                .tips {
                    text-align: center;
                    margin-top: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #999999;
                }
            }
        }
    }
    }
</style>