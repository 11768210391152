<template>
    <div class="page">
        <div class="userInfo">
            <img class="haedImg" :src="weChatUserInfo.headimgurl" alt="">
            <div class="rightInfo">
                <div class="name">
                    <span>{{weChatUserInfo.nickname||'-'}}</span>
                    <img src="../../../static/wowebsite/vip@2x.png" alt="">
                </div>
                <!-- <div class="mobile">
                    <span class="text">188****6789</span>
                    <span class="tip">更换手机</span>
                </div> -->
            </div>
        </div>
        <div class="option">
            <div class="option_box" v-for="(item,index) in list" :key="index" @click="goPage(item)">
                <div class="box_left">
                    <img :src="item.img" alt="">
                    <span>{{item.name}}</span>
                </div>
                <img class="box_img" src="../../../static/wowebsite/shangjiantou.png" alt="">
            </div>
        </div>
        <div style="width:100%;height:50px;"></div>
        <!-- 底部tab -->
        <van-tabbar v-model="active" :active-color="themeColor" inactive-color="#000">
            <van-tabbar-item replace to="/bank/index">
                <span>首页</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../../static/wowebsite/home-active.png" />
                    <img v-else src="../../../static/wowebsite/home.png">
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/wowebsite/subbranch" icon="search">
                <span>支行</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../../static/wowebsite/subranch-active.png" />
                    <img v-else src="../../../static/wowebsite/subranch.png">
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/wowebsite/my" icon="setting-o">
                <span>我的</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../../static/wowebsite/my-active.png" />
                    <img v-else src="../../../static/wowebsite/my.png">
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
    import { website } from "@/mixin/wowebsite.js"
    import { getUserInfo, getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        mixins: [website],
        data() {
            return {
                active: 2,
                list: [{
                    img: require('../../../static/wowebsite/wodeyuyue-2@2x.png'),
                    name: '我的预约',
                    router: '/wowebsite/myappment'
                }, {
                    img: require('../../../static/wowebsite/navicon-yhxy@2x.png'),
                    name: '用户协议',
                    router: '/wowebsite/agree'
                }],
                location_lon: '-',
                location_lat: '-',
                themeColor:'#D9260F',//可配置主题颜色
            }
        },
        created() {
            this.getInfo()//获取用户信息

        },
        mounted() {
            //     wx.ready(() => {
            //     wx.getLocation({
            //       type: 'wgs84',
            //       success: function (res) {
            //         console.log("位置信息",res)
            //         that.location_lat = res.latitude;
            //         that.location_lon = res.longitude;
            //       }
            //     })
            //   })
        },
        methods: {
            //获取分享内容
            shareData() {
                getbankShare({ b: sessionStorage['wowebsiteFun'] }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            //路由跳转
            goPage(row) {
                this.$router.push({
                    path: row.router,
                    query: '',
                });
            },
            //获取用户信息
            getInfo() {
                let that = this;
                //如果为空数据  则重定向页面
                getUserInfo({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.openid === undefined) {
                            let redirectUrl = location.protocol + "//" + location.host + "/auth/bank?b=" + that.b + "&target=" + encodeURIComponent("/wowebsite/my" + location.search);
                            window.location.href = redirectUrl;
                            return
                        }
                        this.weChatUserInfo = res.data
                        sessionStorage['userInfoFun'] = JSON.stringify(res.data)
                        this.shareData()
                    }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        padding: 15px 12px 19px 12px;
        height: 100%;

        .userInfo {
            width: 100%;
            padding: 0 4px;
            display: flex;
            align-items: center;

            .haedImg {
                width: 78px;
                height: 78px;
                border-radius: 50%;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
            }

            .rightInfo {
                margin-left: 12px;

                .name {
                    position: relative;

                    span {
                        font-size: 19px;
                        font-weight: bold;
                        color: #4A4A4A;
                    }

                    img {
                        position: absolute;
                        top: 6px;
                        width: 18px;
                        height: 9px;
                        margin-left: 7px;
                    }
                }

                .mobile {
                    margin-top: 4px;

                    .text {
                        font-size: 12px;
                        font-weight: 400;
                        color: #4A4A4A;
                    }

                    .tip {
                        margin-left: 5px;
                        font-size: 9px;
                        font-weight: 400;
                        color: #BBBBBB;
                        text-decoration: underline
                    }
                }
            }
        }

        .option {
            width: 100%;
            padding: 5px 15px 5px 15px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-top: 20px;

            .option_box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px 0;

                .box_left {
                    span {
                        margin-left: 13px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #515151;
                    }

                    img {
                        width: 12px;
                        height: 12px;
                    }

                }

                .box_img {
                    width: 5px;
                    height: 8px;
                }
            }
        }
    }
</style>