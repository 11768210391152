<template>
    <div :style="{color:color}">
      {{ showMore ? longStr : longStr.slice(0,length) }}
      <img
        class="up"
        v-show="longStr.length>60&&showMore == false"
        @click="handleShowMore"
        :src="imageSrc"
        alt=""
      />
      <img
        class="up"
        v-show="longStr.length>60&&showMore == true"
        @click="handleShowLess"
        :src="imageSource"
        alt=""
      />
    </div>
  </template>
  <script>
  export default {
    props: ['longStr','length','color'],
    data() {
      return {
        showMore: false,
      };
    },
    computed: {
      imageSource() {
        return this.color ? require('../../../assets/fina/collect.png') : require('../../../assets/fina/shang.png');
      },
      imageSrc(){
        return this.color ? require('../../../assets/fina/luanch.png') : require('../../../assets/fina/xia.png');
      }
    },
    methods: {
      handleShowMore(event) {
        event.stopPropagation();
        this.showMore = true;
      },
      handleShowLess(event) {
        event.stopPropagation();
        this.showMore = false;
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .up {
    width: 11px;
    height: 9px;
    padding-bottom: 2px;
  }
  </style>